import { defaultConfig } from 'app/config/environment';
import axios from 'axios';
import { requestWithCancel } from './utils';

export const SEARCH_BOOKS = 'front/contents';
export const GET_CONTENTS = 'front/contents/';
export const GET_BOOK_ITEMS = 'cms/contents/';
export const CONTENT_CMS = 'cms/contents/';
export const REDEEM_LICENSE = 'front/redeem/';
export const REDEEM_PERSONAL_LICENSE = '/licenses/redeem/';
export const ITEMS = 'front/lesson-items';
export const RESOURCES_MATERIALS = 'lms/resource-materials';
export const CONSUMPTIONS = 'consumptions';

export const CONTENTS = 'cms/contents';
export const LESSONS = 'front/lessons';
export const ITEMS_BATCH = 'front/lesson-items-batch';
export const JOBS = 'front/jobs';

export function searchBooks(search) {
  return axios.get(SEARCH_BOOKS, { params: { search: search, isBook: 1, pageSize: 16, offset: 0, orderBy: 't.name asc' } });
}

export function getLesson(guid) {
  return axios.get(LESSONS + '/' + guid);
}

export function getContent(guid) {
  return axios.get(GET_CONTENTS + guid);
}

export function getContentCMS(guid) {
  return axios.get(CONTENTS + '/' + guid);
}

export function updateLessonItem(params) {
  return axios.put(ITEMS + '/' + params.guid, params);
}

export function getLessonItem(params) {
  return axios.get(ITEMS + '/' + params.lessonItemGuid);
}

export function getLessonItems(guid) {
  return axios.get(LESSONS + '/' + guid + '/items');
}

export function getBookItems(guid) {
  return requestWithCancel(GET_BOOK_ITEMS + guid + '/items', {});
}

export function getContentsItem(guid, itemReference) {
  //return axios.get(GET_BOOK_ITEMS+guid+'/items/contents', itemReference ? {params: {referenceId:[itemReference]}} : {})
  return requestWithCancel(GET_BOOK_ITEMS + guid + '/items/contents', itemReference ? { params: { referenceId: [itemReference] } } : {});
}

export function getContents(pageSize = 6, offset = 0, educationLevel = [], educationYear = [], discipline = [], group = 0, isBook = 0, haveThumbnail = 1) {
  return axios.get(GET_CONTENTS, {
    params: {
      pageSize: pageSize,
      offset: offset,
      educationLevel: educationLevel,
      educationYear: educationYear,
      discipline: discipline,
      group: group,
      isBook: isBook,
      haveThumbnail: haveThumbnail,
    },
  });
}

export function getContentsCMS(params) {
  return axios.get(CONTENTS, { params });
}

export function createContentCms(params) {
  return axios.post(CONTENT_CMS, params);
}

export function getContentCms(guid) {
  return axios.get(CONTENT_CMS + guid);
}

export function getContentsCmsParams(params) {
  return axios.get(CONTENT_CMS, params);
}

export function redeemLicense(params) {
  if (params.token) {
    return axios.post(REDEEM_LICENSE, params, {
      headers: {
        Authorization: params.token,
      },
    });
  }
  return axios.post(REDEEM_LICENSE, params);
}

export function redeemPersonalLicense(params) {
  return axios.post(REDEEM_PERSONAL_LICENSE, params);
}

export function deleteContent(params) {
  return axios.delete(CONTENT_CMS, { data: params });
}

export function createContent(params) {
  return axios.post(CONTENTS, params);
}

export function createLessonItem(params) {
  return axios.post(ITEMS, params);
}

export function createLessonItemsBatch(params) {
  return axios.post(ITEMS_BATCH, params);
}

export function getTicket(guid) {
  return axios.get(CONTENTS + '/' + guid + '/content/upload', {
    headers: {
      'app-referer': defaultConfig.API_URL,
    },
  });
}

export function reorderLessonItems(params) {
  return axios.put(ITEMS, params);
}

export function createTest(params) {
  return axios.post(CONTENTS, params);
}

export function addTestToLesson(params) {
  return axios.post(ITEMS, params);
}

export function updateTest(params) {
  return axios.put(ITEMS + '/' + params.guid, params);
}

export function updateContent(params) {
  return axios.put(CONTENTS + '/' + params.guid, params);
}

export function markContent(params) {
  return axios.put(JOBS + '/' + params.guid, params);
}

export function getJob(params) {
  return axios.get(JOBS + '/' + params.guid + `?course=${params.courseGuid}&lessonItem=${params.lessonItemGuid}`);
}

export function getJobs(params, courseGuid, customized) {
  let path = ITEMS + '/' + params;
  if (courseGuid) path = path + `?courseGuid=${courseGuid}`;
  return axios.get(path, { params: { ...(customized && { customized_by: customized }) } });
}

export function uploadFromGoogleDrive(contentGuid, fileId, oauthToken, innerFolder) {
  const params = { fileId: fileId, oauthToken: oauthToken };
  if (innerFolder && innerFolder.toString().length > 0) {
    params.innerFolder = innerFolder;
  }
  return axios.post(CONTENT_CMS + contentGuid + '/content/upload-from-google-drive', params);
}

export function uploadFromGoogleDriveThumbnail(contentGuid, fileId, oauthToken) {
  const params = { fileId: fileId, oauthToken: oauthToken };
  return axios.post(CONTENT_CMS + contentGuid + '/thumbnail/upload-from-google-drive', params);
}

export function uploadContentToJob(jobGuid, courseGuid, lessonItemGuid) {
  return axios.post(JOBS + '/' + jobGuid + `/files?uploader=froala&course=${courseGuid}&itemGuid=${lessonItemGuid}`, {});
}

export function createMaterial(params) {
  return axios.post(RESOURCES_MATERIALS, params);
}

export function deleteMaterial(params = { guid: [] }) {
  return axios.delete(RESOURCES_MATERIALS, { data: params });
}

export function updateMaterial(params = { guid: [] }) {
  return axios.patch(RESOURCES_MATERIALS + '/' + params.guid, { data: params });
}

export function cloneContent(params) {
  return axios.post('cms/contents-clone', params);
}

export function deleteLessonItem(params) {
  return axios.delete(ITEMS, { data: params });
}

export function postConsumption(params) {
  return axios.post(CONSUMPTIONS, params);
}
